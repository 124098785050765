export const CONFIG = {
  API_URL: "https://api.iofmtms.com",
  mapboxAccessToken:
    "pk.eyJ1IjoiYmZnbmVpbCIsImEiOiJjbGR2azExeW4wZ28yM29tZm4xZjJwdHFxIn0.Mdv--BkZwKSw-pdTRMQdPg",
  mapStyle: "mapbox://styles/bfgneil/cle2sqoh8003s01ogafzo3xsl",
  siteTitle: "IoFMT Managed Services",
  siteTitleDescription: "",
  battery_minMV: 2300,
  battery_maxMV: 3000,
  shareUrl: "https://iris.iofmtms.com/share/",
  dashboardShareUrl: "https://iris.iofmtms.com/share/dashboard/",
  websiteURL: "https://iris.iofmtms.com",
  Auth0Domain: "iofmtms.uk.auth0.com",
  Auth0ClientId: "k8CAT0PsUzBlfJ8MkJaBuWdJstnWICnc",
  Auth0Audience: "http://api.iofmtms.com",
  primaryColour: "#084F6A",
  primaryColourRGB: {
    r: 8,
    g: 79,
    b: 106,
  },
  secondaryColour: "#528396",
  defaultTheme: "light",
  defaultLanguage: "en",
  locale: "en",
  organisation_enabled: true,
  billing_enabled: false,
  manual_add: false,
  self_host: false,
  feedback_enabled: false,
  feedback_url: "",
  show_geofences: false,
  api_key_enabled: false,
  register_card_enabled: false,
  defaultDateFormat: "DD/MM/YYYY",
  show_buy_link: false,
  buy_link: "https://hexaspot.com/collections/trackpac",
};
